import React from 'react';

import ContactForm from "../components/contactform"
import ProductCategories from "../components/productcategories"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Menu from "../components/menu"

const AboutUsPage = () => {
    return (
        <Layout>
            <SEO title="Antikviteter, Lyxiga klockor, smycken & antika tavlor" />
            
            <Menu top={true} />
            <section className="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg">
                            <h2>Om oss</h2>
                            <strong>Mer info. inom kort</strong>
                        </div>
                        <div className="col-lg-4 pt-5 pt-lg-0 mt-5 mt-lg-0">
                            <img src="https://scontent-cph2-1.cdninstagram.com/v/t51.2885-15/e35/137594560_168070331766997_8320045536128372661_n.jpg?_nc_ht=scontent-cph2-1.cdninstagram.com&_nc_cat=101&_nc_ohc=wgZvKU4UYOwAX_baSwR&tp=1&oh=7e44647d57db637e6e4c2e60ca1aceb5&oe=6024E314"  className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <ProductCategories />
            <ContactForm 
                text={{ 
                h2: 'Behov av värdering? – Svar inom 24h',
                h3: 'Oavset om det är en stor eller liten pjäs så hjälper vi till med värdering.'
                }} 
            />
            <Footer />
        </Layout>
    );
};

export default AboutUsPage;